import React from "react"
import { Container, Image, Row, Col } from "react-bootstrap"

import Image01 from "../../../images/koenigsschiessen/2010/01.jpg"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const Koenigsschiessen2010 = props => {
  return (
    <Layout>
      <SEO title="Königsschießen 2010" lang="de" />
      <Container>
        <h1 className="h3">Königsschießen 2010</h1>
        <hr className="featurette-divider" />
        <p>
          Am vergangenen Samstag veranstaltete der Schützenverein Gambach sein
          Sommerfest mit dem 15. Königsschießen. Auch dieses Jahr fanden sich
          wieder viele Schützenschwestern und –brüder im Schützenhaus Gambach zu
          dieser traditionellen Veranstaltung ein. Der Vorsitzende Friedhelm
          Schwenz konnte neben den erschienenen Mitgliedern auch einige Gäste
          begrüßen und bedankte sich bei dem Vorbereitungsteam für die
          Organisation sowie den Spendern der Kuchen und Salate. Der Nachmittag
          begann mit einer gemütlichen Kaffeetafel, bei der man sich die
          verschiedenen selbstgebackenen Kuchen zum Kaffee schmecken ließ.
        </p>

        <Row className="justify-content-center">
          <Col xs={8}>
            <Image src={Image01} fluid={true} className="d-block mx-auto" />
            <small className="d-block text-center">
              Nach dem Wettkampf präsentierte sich die neue Königsfamilie
              (hintere Reihe von links): Rigo Schubach, Salisa Schubach,
              Katharina Beppler-Alt, Friedhelm Schwenz und Eberhard Beppler-Alt.
              Vorne von links: Rajana Schubach, Nico Ohlemutz, Denise Döring und
              Loni Buß
            </small>
          </Col>
        </Row>

        <p></p>

        <p>
          Gegen 15 Uhr traten 24 Schützinnen und Schützen sowie die Jüngsten des
          Vereins zum diesjährigen Königsschießen an. Mit dem Luftgewehr wurde
          aus einer Entfernung von 10m auf die hölzernen, von Michael Rheinfrank
          angefertigten Königsadler geschossen. Nach knapp eineinhalbstündigem
          Wettkampf standen die Würdenträger für das Jahr 2010 fest. In diesem
          Jahr wurden Katharina Beppler-Alt Schützenkönigin, Friedhelm Schwenz
          errang den Titel des Schützenkönig – sie erhielten die silbernen
          Königsketten mit dem aktuellen Kettenanhängsel. 1. Edelfräulein wurde
          Loni Buß und 1. Ritter Eberhard Beppler-Alt; als 2. Edelfräulein sowie
          2. Ritter vervollständigten Denise Döring und Rigo Schubach die
          Königsfamilie. Bei den jugendlichen Schützen waren Salisa Schubach,
          Nico Ohlemutz und Rajana Schubach erfolgreich. Auch hier konnte der
          „Miniadler“ fachgerecht und zielsicher „erlegt“ werden. Nach dem
          Wettkampf klang das Sommerfest mit gegrillten Köstlichkeiten, die wie
          immer von Rigo Schubach zubereitet wurden, in gemütlicher Runde aus.
        </p>
      </Container>
    </Layout>
  )
}

export default Koenigsschiessen2010
